import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { LoginResponse } from '@models/api/login-response.model';
import { environment } from '@environments/environment';
import { StorageService } from '@services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  clientCredentials: string;
  base64Credentials: string;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) {
    this.clientCredentials = `${environment.authUser}:${environment.authPassword}`;
    this.base64Credentials = btoa(this.clientCredentials);
  }

  login(username: string, password: string): Observable<LoginResponse> {
    const body = `grant_type=password&username=${encodeURIComponent(
      username,
    )}&password=${encodeURIComponent(password)}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        Authorization: 'Basic ' + this.base64Credentials,
      }),
    };

    return this.http
      .post<LoginResponse>(
        `${environment.apiUrl}/uaa/oauth/token`,
        body,
        httpOptions,
      )
  }

  setToken(token: string): void {
    this.storageService.set('_token', token);
  }

  getToken(): string | null {
    return this.storageService.get('_token');
  }

  isLogged(): boolean {
    const token = this.storageService.get('_token');
    return token ? true : false;
  }

  logout(onDone?: () => void): void {
    this.storageService.remove('_token');

    if (onDone) {
      onDone();
    }
  }
}
